@media only screen and (min-width: 1200px) {
  .wrapper {
    max-width: 800px;
  }
 
  .tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
  }
 
  .tooltip .tooltip-text {
    width: 350px;
    top: 100%;
    left: 10%;
    visibility: hidden;
    background-color: #00ABA2;
    color: #fff;
    padding: 8px 5px 5px 8px;
    border-radius: 6px;
    position: absolute;
    z-index: 100;
    opacity: 0;
    transition: 0.3s;
    transform: translate3d(0px, 20px, 0px);
  }
 
  .tooltip:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
    transform: translate3d(0px, 0px, 0px);
  }
}

@media only screen and (min-width: 700px) {
  .wrapper {
    max-width: 800px;
  }
 
  .tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
  }
 
  .tooltip .tooltip-text {
    width: 200px;
    visibility: hidden;
    background-color: #00ABA2;
    color: #fff;
    padding: 8px 5px 5px 8px;
    border-radius: 6px;
    z-index: 100;
    opacity: 0;
    transition: 0.3s;
    transform: translate3d(0px, 20px, 0px);
    position: absolute;
    top: 125%;
    left: 50%;
    margin-left: -70px;
    
  }
 
  .tooltip:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
    transform: translate3d(0px, 0px, 0px);
  }
}


@media only screen and (max-width: 700px) {
  .wrapper {
    max-width: 800px;
  }
 
  .tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
  }
 
  .tooltip .tooltip-text {
    width: 130px;
    visibility: hidden;
    background-color: #00ABA2;
    color: #fff;
    padding: 8px 5px 5px 8px;
    border-radius: 6px;
    z-index: 100;
    opacity: 0;
    transition: 0.3s;
    transform: translate3d(0px, 20px, 0px);
    position: absolute;
    top: 125%;
    left: 50%;
    margin-left: -70px;
    
  }
 
  .tooltip:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
    transform: translate3d(0px, 0px, 0px);
  }
}