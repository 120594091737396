html {
  width: 100%;
  margin: 0px;
  padding: 0px;
}

body {
  height: 100%;
  margin: 0px;
  padding: 0px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.blog-container {
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px auto;
}
.blog-container h1 {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 60px;
}
.blog-container .blog-wrapper-d {
  margin-bottom: 100px;
}
.blog-container h2 {
  font-size: 1.5rem;
  font-weight: 700;
  margin: 0 0 20px 0;
}
.blog-container p {
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 10px;
  padding: 10px 0px;
  text-align: left;
}
.blog-container img {
  width: 100%;
}

.blog-navbar {
  background-color: #66ccc7;
  width: 100%;
  height: 200px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.blog-navbar a {
  background-color: white;
  padding: 10px 20px;
  border-radius: 5px;
  margin: 10px;
  font-weight: 500;
}
.blog-navbar a:hover {
  text-decoration: none;
  background: #76e0db;
}

.footer-blog-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #66ccc7;
  padding: 20px;
}
.footer-blog-container .social a {
  margin: 5px;
}
.footer-blog-container .social a img {
  height: 60px;
}

.footer-blog-container .links a {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.topNav button {
  background-color: #007fb1 !important;
  color: white !important;
}

/* media queries for large screens */
@media (min-width: 750px) {
  .blog-container {
    width: 50%;
  }
  .blog-navbar {
    height: 80px;
  }
  .footer-blog-container .below {
    display: flex;
  }
  .footer-blog-container .below .links {
    margin-left: 20px;
  }
  .blog-container .image-wrapper {
    margin: 0 auto;
    max-width: 50%;
  }
}
