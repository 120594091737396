/* Bannað að breyta */

@media print {
	.noprint {
		display: none;
	}

	body,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	ol,
	ul,
	img,
	div {
		width: auto;
		border: 0;
		margin: 0 5%;
		padding: 0;
		float: none;
		position: static;
		overflow: auto;
	}

	.customprint::-webkit-scrollbar{
		display: none;
	}
}
