.ScrollButtonContainer .ScrollButtonItem:hover {
	border-radius: 5px;
}

.ScrollButtonContainer .ScrollButtonItem {
	position: fixed;
	width: 50px;
	left: 90%;
	bottom: 5%;
	height: 5%;
	font-size: 200%;
	z-index: 100;
	border-radius: 10px;
	box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px,
		rgba(6, 24, 44, 0.65) 0px 4px 6px -1px,
		rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
	-webkit-transition: all 0.1s linear;
	transition: all 0.1s linear;
}
