.NavbarLink {
	text-decoration: none;
	position: relative;
	display: block;
}

.NavbarContainer .NavbarLink:hover {
	text-decoration: none;
	/* -webkit-transform: scale(1.03);
	-moz-transform: scale(1.03);
	-ms-transform: scale(1.03);
	-o-transform: scale(1.03);
	transform: scale(1.03); */
	text-shadow:0px 0px 1px black;
}

.NavbarContainer .NavbarLink {
	-webkit-transition: all 0.1s linear;
	transition: all 0.1s linear;
}
