.HoverContainer .HoverItem:hover {
	text-decoration: none;
	-webkit-transform: scale(1.03);
	-moz-transform: scale(1.03);
	-ms-transform: scale(1.03);
	-o-transform: scale(1.03);
	transform: scale(1.03);
}

.HoverContainer .HoverItem {
	-webkit-transition: all 0.1s linear;
	transition: all 0.1s linear;
}
