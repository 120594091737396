/* Root */
#google_translate_element {
  background-color: white !important;
  border-radius: 8px !important;
  min-height: 48px;
  line-height: 24px;
}

#google_translate_element a:hover {
  text-decoration: none;
}

/* Container */
.goog-te-gadget-simple {
  display: flex !important;
  justify-content: center !;
  padding: 12px 10px 12px 10px !important;
  background-color: #ffffff !important;
  border-radius: 8px;
  border: none !important;
  font-size: 18px !important;
  font-family: "IBM Plex Sans", San Francisco, Segoe UI, sans-serif;
  font-weight: 600 !important;
  transition: background-color 0.2s !important;
}
.goog-te-gadget-simple:hover {
  background-color: #00ded2 !important;
}

@media only screen and (max-width: 768px) {
  .goog-te-gadget-simple {
    padding: 10px 10px 11px 10px !important;
    font-size: 14px !important;
  }
}

/* Remove Google icon */
.goog-te-gadget-icon {
  display: none !important;
}

/* Remove underline */
.goog-te-menu-value {
  text-decoration: none !important;
}

/* Remove extra lines and images */
.goog-te-gadget-simple span a img {
  display: none !important;
}
.goog-te-gadget-simple span a span:nth-child(2) {
  display: none !important;
}
.goog-te-gadget-simple span a span:nth-child(3) {
  display: none !important;
}
.goog-te-gadget-simple span a span:nth-child(4) {
  display: none !important;
}
.goog-te-gadget-simple span a span:nth-child(5) {
  display: none !important;
}

/* Change text */
/* #google_translate_element
  .goog-te-gadget-simple
  .goog-te-menu-value
  span:first-child {
  display: none;
} */
/* #google_translate_element .goog-te-gadget-simple .goog-te-menu-value:before {
  content: "Tungumál";
} */
